import React, { useEffect, useMemo, useState } from 'react';
import { HeadFC, Link, navigate } from 'gatsby';
import {
    getAuth,
    linkWithPopup,
    unlink,
    OAuthProvider,
    fetchSignInMethodsForEmail,
    EmailAuthProvider,
    signInWithPopup,
    GoogleAuthProvider,
    sendPasswordResetEmail,
    reauthenticateWithPopup
} from 'firebase/auth';
import {
    Box,
    Button,
    Container,
    Typography,
    CircularProgress,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Alert
} from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google'; 
import AppleIcon from '@mui/icons-material/Apple';
import MicrosoftIcon from '@mui/icons-material/Microsoft';
import PasswordIcon from '@mui/icons-material/Lock';
import { auth, functions } from '../../firebase/config'; 
import { getFunctions, httpsCallable } from 'firebase/functions';
import { openBillingPortalWindow } from '../../utils/redirectToBillingPortal';
import MotionBox from '../../components/motion/motionBox';
import Colors from '../../constants/colors';
import LayoutType from '../../constants/layout-type';
import { ArrowForward } from '@mui/icons-material';

const AccountPage: React.FC = () => {
    const user = auth.currentUser;
    const [loading, setLoading] = useState(false);
    const [linkedProviders, setLinkedProviders] = useState<string[]>([]);
    const [emailPasswordExists, setEmailPasswordExists] = useState(false);
    const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [stripeError, setStripeError] = useState('');

    useEffect(() => {
        if (!user) {
            navigate('/auth/login');
        } else {
            fetchLinkedProviders();
        }
    }, [user]); // Depend on 'user' to re-fetch when the user changes

    const refreshUserAuthState = async () => {
        await auth.currentUser.reload().then(() => {
            console.log('User auth state refreshed');
            fetchLinkedProviders(); // Call fetchLinkedProviders again after state refresh
        }).catch(error => {
            console.error('Error refreshing user auth state:', error);
        });
    };

    const fetchLinkedProviders = async () => {
        setLoading(true);
        if (user) {
            try {
                const signInMethods = await fetchSignInMethodsForEmail(auth, user.email);
                setLinkedProviders(signInMethods.map(method => {
                    // Convert Firebase method strings to a more friendly format if needed
                    if (method === 'password') return 'email';
                    if (method.includes('google.com')) return 'google';
                    return method; // Or handle other methods as needed
                }));
                setEmailPasswordExists(signInMethods.includes(EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD));
            } catch (error) {
                console.error('Error fetching linked providers:', error);
            }
        }
        setLoading(false);
    };

    const handleLinkProvider = async (provider: OAuthProvider) => {
        setLoading(true);
        try {
            await linkWithPopup(user!, provider);
            fetchLinkedProviders(); // Refresh linked providers
            alert(`${provider.providerId} account linked successfully`);
        } catch (error) {
            console.error(`Error linking ${provider.providerId} account:`, error);
            alert(`Failed to link ${provider.providerId} account`);
        } finally {
            setLoading(false);
        }
    };

    const handleUnlinkProvider = async (providerId: string) => {
        setLoading(true);
        try {
            await unlink(user!, providerId);
            fetchLinkedProviders(); // Refresh linked providers
            alert(`${providerId} account unlinked successfully`);
        } catch (error) {
            console.error(`Error unlinking ${providerId} account:`, error);
            alert(`Failed to unlink ${providerId} account`);
        } finally {
            setLoading(false);
        }
    };

    const handleAddPassword = async () => {
        if (!auth.currentUser || !newPassword.trim()) return;
        setLoading(true);
        try {
            const credential = EmailAuthProvider.credential(auth.currentUser.email!, newPassword);
            await linkWithPopup(auth.currentUser, credential);
            alert('Password set successfully');
            setPasswordDialogOpen(false);
            fetchLinkedProviders(); // Refresh linked providers list
        } catch (error) {
            console.error('Error adding password:', error);
            alert('Failed to set password');
        } finally {
            setLoading(false);
        }
    };

    // Function to send password reset email
    const handleSendPasswordResetEmail = async () => {
        if (!auth.currentUser) return;
        setLoading(true);
        try {
            await sendPasswordResetEmail(auth, auth.currentUser.email!);
            alert('Password reset email sent successfully.');
        } catch (error) {
            console.error('Error sending password reset email:', error);
            alert('Failed to send password reset email.');
        } finally {
            setLoading(false);
        }
    };

    const redirectToBillingPortal = async () => {
        const checkoutManager = openBillingPortalWindow('Billing Portal Loading', 'Directing you to the billing portal. Please wait...');
        const createPortalLink = httpsCallable(functions, 'createPortalLink');
        try {
            const { data } = await createPortalLink({
                returnUrl: window.location.origin + '/app/billing-end/',
                locale: "auto",
            });

            checkoutManager.redirectTo(data.url);
        } catch (error: any) {
            if (error.message.indexOf('stripeId') > -1)
            {
                setStripeError('You do not have a Stripe account linked to your Screen Keep account. Please contact support.');
            }
            checkoutManager.closeWindow();
        }

        // Check if the window is closed every few seconds.
        const interval = setInterval(() => {
            if (checkoutManager.isClosed()) {
                clearInterval(interval); // Stop checking once the window is closed.
                // Perform any additional cleanup or state updates needed.
            }
        }, 2000); // Check every 2 seconds.
    };


    const firstBoxAnimationProps = useMemo(() => ({
        initial: { opacity: 0, y: -50 },
        animate: { opacity: !loading ? 1 : 0, y: 0 },
        exit: { opacity: 0, y: -50 },
        transition: { duration: 1 },
        style: { visibility: !loading ? 'visible' : 'hidden', background: Colors.BACKGROUND_DARK }
    }), [loading]);

    // UI for managing third-party links and password management
    return (
        <>
        
        <MotionBox
            {...firstBoxAnimationProps}
        >
            <Container maxWidth="md">
                <Box textAlign="center" pt={{ xs: 8, sm: 10, md: 13 }} pb={{ xs: 2, sm: 3, md: 4 }} mb={{ xs: 1, sm: 2, md: 3 }} flexDirection="column" flex="1" alignItems="center" alignContent="center">
                    <Typography component="h1" variant="h1" sx={{ mt: { xs: 2, sm: 3, md: 4 }, mb: 0, maxWidth: "100%", fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' } }}>
                        Account Settings    
                    </Typography>
                    <Typography variant="body1" sx={{ mb: { xs: 1, sm: 2, md: 4 }, mt: { xs: -2, sm: -1, md: 0 }, fontSize: { xs: '0.875rem', sm: '1rem', md: '1.25rem' } }}>
                        Update your Screen Keep account settings and manage linked accounts.
                    </Typography>
                </Box>
            </Container>
        </MotionBox>
            
            <Container component="main" maxWidth="lg" sx={{ mt: 2 }}>
                {loading && <CircularProgress />}
                {!loading && (
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={6} sx={{ textAlign: 'center' }}>
                            <Box><Typography variant="h6" sx={{ mt: 2, mb: 1 }}>Linked Accounts</Typography></Box>
                            <Typography variant="subtitle2" sx={{ mb: 2 }}>Link your account with other providers to enable easy sign-in.</Typography>
                            <Box>
                                {linkedProviders.includes('google') ? (
                                    <Button startIcon={<GoogleIcon />} onClick={() => handleUnlinkProvider('google.com')} sx={{ mt: 1 }}
                                        fullWidth
                                        sx={{
                                            mt: 1,
                                            maxWidth: 450
                                        }}
                                        variant="outlined">
                                        Unlink Google
                                    </Button>
                                ) : (
                                        <Button startIcon={<GoogleIcon />} onClick={() => handleLinkProvider(new GoogleAuthProvider())} sx={{ mt: 1 }}
                                            fullWidth
                                            sx={{
                                                mt: 1,
                                                maxWidth: 450
                                            }}
                                            variant="outlined">
                                        Link Google
                                    </Button>
                                )}
                            </Box>

                            <Box><Typography variant="h6" sx={{ mt: 4, mb: 1 }}>Password Management</Typography></Box>
                            <Typography variant="subtitle2" sx={{ mb: 2 }}>Set up a password for your account or reset your password.</Typography>
                            {emailPasswordExists ? (
                                <Button startIcon={<PasswordIcon />} onClick={handleSendPasswordResetEmail}
                                    fullWidth
                                    sx={{
                                        mt: 1,
                                        maxWidth: 450
                                    }}
                                    variant="outlined">
                                    Send Password Reset Email
                                </Button>
                            ) : (
                                    <Button startIcon={<PasswordIcon />} onClick={() => setPasswordDialogOpen(true)}
                                        fullWidth
                                        sx={{
                                            mt: 1,
                                            maxWidth: 450
                                        }}
                                        variant="outlined">
                                    Set up Password
                                </Button>
                            )}

                            <Dialog open={passwordDialogOpen} onClose={() => setPasswordDialogOpen(false)}>
                                <DialogTitle>Add Password</DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        To secure your account, please enter a new password.
                                    </DialogContentText>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="password"
                                        label="New Password"
                                        type="password"
                                        fullWidth
                                        variant="outlined"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => setPasswordDialogOpen(false)}>Cancel</Button>
                                    <Button onClick={handleAddPassword}>Add Password</Button>
                                </DialogActions>
                            </Dialog>
                        </Grid>


                        <Grid item xs={12} md={6} sx={{ textAlign:'center'} }>
                            <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>Manage Billing</Typography>
                            <Typography variant="subtitle2" sx={{ mb: 2 }}>Manage your subscription and billing details.</Typography>
                            <Box>
                                <Button variant="contained"
                                    color="primary" onClick={redirectToBillingPortal}
                                    fullWidth
                                    sx={{
                                        mt: 1,
                                        maxWidth: 300
                                    }}
                                    endIcon={<ArrowForward />}>
                                    Manage Billing
                                </Button>
                            </Box>
                            {stripeError.length > 0 &&
                                <Alert severity="error" sx={{ mt: 2 }}>
                                    Billing management is only available after your first purchase. Check back after your first order.
                                </Alert>
                            }
                        </Grid>
                    </Grid>
                )}
            </Container>
        </>
    );
};


AccountPage.layoutType = LayoutType.DefaultFullWidth;

export default AccountPage;


export const Head: HeadFC = () => <title>ScreenKeep - AccountSettings</title>