import Colors from "../constants/colors";

// Define an interface for the return type to provide structure and for better code assistance.
export interface ICheckoutWindow {
    redirectTo: (url: string) => void;
    closeWindow: () => void;
    isClosed: () => boolean;
}

export const openBillingPortalWindow = (title: String, loadingMessage: String): ICheckoutWindow => {
    let checkoutWindow = window.open('', '_blank'); // Open a blank window immediately
    checkoutWindow.location.href = 'about:blank';

    // Try to bring the new window to the front
    checkoutWindow?.focus();

    // Set the loading screen HTML
    checkoutWindow?.document.write(`
<!DOCTYPE html>
<html>
<head>
    <title>${title}</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <style>
        body, html {
            height: 100%;
            margin: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: ${Colors.PRIMARY};
            font-family: Arial, sans-serif;
            color: white;
        }
        .loading-text {
            font-size: 20px;
            text-align: center;
            margin-bottom: 25px;
        }
        .progress-bar {
            width: 70%;
            height: 4px;
            background-color: rgba(255, 255, 255, 0.2);
            position: relative;
            overflow: hidden;
        }
        .progress {
            position: absolute;
            height: 100%;
            left: 0%;
            width: 0%;
            background-color: #fff;
            animation: loading 3s ease-in-out infinite;
        }
        @keyframes loading {
            0% { left: 0%; width: 0%; }
            50% { width: 100%; }
            100% { left: 100%; width: 0%; }
        }
    </style>
    <script>
        window.onload = function() {
            history.pushState(null, '', '/app/billing-end/');
            window.onpopstate = function(event) {
                window.location.href = '/app/billing-end/';
            };
        };
    </script>
</head>
<body>
    <svg id="b" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
        style="max-width:80%;max-height: 200px">
        <defs>
            <style>
                .d {
                    fill: ${Colors.BACKGROUND};
                }
            </style>
        </defs>
        <path class="d" d="M21 6h-7.59l3.29-3.29L16 2l-4 4-4-4-.71.71L10.59 6H3c-1.1 0-2 .89-2 2v12c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V8c0-1.11-.9-2-2-2zm0 14H3V8h18v12zM9 10v8l7-4z"></path>
    </svg>
    <h1>Screen Keep</h1>
    <div class="loading-text">${loadingMessage}</div>
    <div class="progress-bar">
        <div class="progress"></div>
    </div>
</body>
</html>
`);
    checkoutWindow?.document.close(); // Close the document stream

    // Return an object that allows redirecting and closing the window.
    return {
        redirectTo: (url: string) => {
            if (checkoutWindow)
                checkoutWindow.location.href = url;
        },
        closeWindow: () => {
            checkoutWindow?.close();
        },
        isClosed: () => {
            return checkoutWindow?.closed || false;
        }
    };
};